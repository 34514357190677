<script setup>
import Navbar from "~/components/Navbar.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const shouldShowNavbar = computed(
  () =>
    !route.path.startsWith("/patient-details/") &&
    !route.path.startsWith("/login")
);
</script>

<template>
  <div class="bg-gray-100 min-h-screen flex flex-col">
    <Navbar v-if="shouldShowNavbar" />
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>
